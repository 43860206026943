import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// import axios from "axios";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "../src/assets/css/style.css";
import "./portal/assets/css/portal.css";
import 'react-tabs/style/react-tabs.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from "react-hot-toast";
import { useUserContext } from "./context/UserContext";
import LandingPage from "./pages/LandingPage";
import SupportAgent from './components/Support/SupportAgent';
import CareersPage from "./pages/CareersPage";
const Home = React.lazy(() => import("./pages/Home"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const FAQs = React.lazy(() => import("./pages/FAQs"));
const ConvoScribe = React.lazy(() => import("./pages/ConvoScribe"));
const VirtualScribe = React.lazy(() => import("./pages/VirtualScribe"));
const Contact = React.lazy(() => import("./pages/Contact"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const Blogs = React.lazy(() => import("./pages/Blogs"));
const Blog = React.lazy(() => import("./pages/Blog"));
const BookDemo = React.lazy(() => import("./pages/BookDemo"));
const AppPrivacyPolicy = React.lazy(() => import("./pages/AppPrivacyPolicy"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndConditions")
);
const Loading = React.lazy(() => import("./portal/components/Loading"));
const Pricing = React.lazy(() => import("./pages/Pricing"));

const Error = React.lazy(() => import("./portal/pages/Error"));

const App = () => {
    const { userAuth, userData, isLoading } = useUserContext();

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
        <Suspense fallback={<Loading />}>
            <Toaster position="top-right" reverseOrder={false} />
            <Routes>
                <Route
                    path="/"
                    element={<Home userAuth={userAuth} userData={userData} />}
                />        
                <Route
                    path="/about-us"
                    element={<AboutUs userAuth={userAuth} userData={userData} />}
                />              
                <Route
                    path="/faqs"
                    element={<FAQs userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/contact-us"
                    element={<Contact userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/convo-scribe"
                    element={<ConvoScribe userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/virtual-scribe"
                    element={<VirtualScribe userAuth={userAuth} userData={userData} />}
                />    
                
                <Route
                    path="/ai-scribe"
                    element={<LandingPage userAuth={userAuth} userData={userData} />}
                />          

                <Route
                    path="/privacy-policy"
                    element={<PrivacyPolicy userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/careers"
                    element={<CareersPage userAuth={userAuth} userData={userData} />}
                />
                <Route
                    path="/app-privacy-policy"
                    element={<AppPrivacyPolicy userAuth={userAuth} userData={userData} />}
                />          
                <Route
                    path="/terms-and-conditions"
                    element={
                    <TermsAndConditions userAuth={userAuth} userData={userData} />
                    }
                />
                
                <Route
                    path="/pricing"
                    element={<Pricing userAuth={userAuth} userData={userData} />}
                />
                
                <Route
                    path="/blogs"
                    element={<Blogs userAuth={userAuth} userData={userData} />}
                /> 
                <Route
                    path="/book-demo"
                    element={<BookDemo userAuth={userAuth} userData={userData} />}
                />                   
                <Route
                    path="/blog/:slug"
                    element={<Blog userAuth={userAuth} userData={userData} />}
                />
                <Route path="*" element={<Error />} />
                <Route path="/plans-pricing" element={ <Navigate to="/pricing"/> } />
            </Routes>
        </Suspense>
        <SupportAgent />
        </>
    );
};

export default App;
