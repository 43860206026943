import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';

// Sources
import MiddleBanner from '../components/MiddleBanner';
import Footer from '../components/Footer';
import Accordion from 'react-bootstrap/Accordion';
import Img4 from '../assets/images/LandingPage/Img4.webp';
import Img5 from '../assets/images/LandingPage/Img5.webp';
import FooterLogo from '../assets/images/Home/New/FooterLogo.png';
import Banner from '../assets/images/LandingPage/Banner.webp';
import { Link } from 'react-router-dom';
import Space from '../components/Space';
import Mobile from '../assets/images/convoscribe/Mobile.png';
import QrCode1 from '../assets/images/Home/qrcode1.png';
import QrCode2 from '../assets/images/Home/qrcode2.png';
import GooglePlay from '../assets/images/Home/GooglePlay.png';
import AppStore from '../assets/images/Home/AppStore.png';
import { Check, CheckLg, Quote } from 'react-bootstrap-icons';
import PageHeaderLanding from '../components/PageHeaderLanding';
import TestimonialsLandingPage from '../components/TestimonialsLandingPage';
import ClientsSlider from '../components/ClientsSlider';
import MonthlyPricingLanding from '../components/MonthlyPricingLanding';
import YearlyPricingLanding from '../components/YearlyPricingLanding';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import HeaderLogo from '../assets/images/Home/HeaderLogo.png'

const LandingPage = (props) => {

  // useEffect(()=>{
  //   window.scrollTo(0,0)
  // },[])
  
  return (
    <>
      <Helmet>
      <title> AI Medical Scribe Solution for Healthcare - Scribe Medix </title> 
      <meta name="robots" content="noindex"/>
      </Helmet>

      <section id='landing-page'  className="bg-grey text-lato">
      <section className="container-fluid d-block d-md-none py-3 px-4 bg-white sticky-top shadow-ss-1">
              <div className="row align-items-center">
                <div className="col-5 px-0">
                <img className='w-100' src={HeaderLogo} alt="Header Logo" />
                </div>
                <div className="col-3 fs-ss-12 text-end px-0">
                
          {/* <Link to="/book-demo" className="text-decoration-none text-dark fw-700"> Book Demo </Link> */}
          </div>

          <div className="col-4 fs-ss-12 text-end px-0">
          <a id='cta-signup' className='bg-ss-primary text-decoration-none text-white rounded-5 px-3 py-2' href={`${process.env.REACT_APP_PORTAL_URL}/signup`}>
          Try for Free
            </a>
                </div>
              </div>
            </section>
          <section id='website-responsive'  className="container-fluid border-bottom-mb pb-2 pb-md-0 px-0 bg-landing-page">
            <PageHeaderLanding/>
              <section className="container-fluid">
                  <div className="row justify-content-center align-items-center py-4 gy-4 parent">
                  <div className="col-12 col-md-6 d-block d-md-none ">
                  <h1 className="fs-sub-banner fw-700 text-dark d-block d-md-none text-center">
                            <span className='ls-2'> Turn Hours of Notes into </span> <br className='' /> <span className='text-ss-primary text-uppercase fs-banner'> Minutes of Care </span> 
                          </h1>
                      <img src={Banner} className='w-100' alt="" />
                      </div>
                      <div className="col-12 col-md-6 col-lg-5 pb-md-5 text-center text-md-start">
                        <div className="child">
                          <h1 className="fs-sub-banner fw-700 text-dark d-none d-md-block">
                            <span className='ls-2'> Turn Hours of Notes into </span> <br className='' /> <span className='text-ss-primary text-uppercase fs-banner'> Minutes of Care </span> 
                          </h1>
                          <h2 className="fs-sub-banner2 pt-md-2 fw-400-500-mb text-dark lh-sm" style={{letterSpacing:"0.3px"}}>
                          Let Our AI Medical Scribe Handle <br className="d-block d-md-none" /> Clinical Documentation 
                          </h2>
                          <div className='pt-3 pt-lg-5'>
                            <div className="d-flex justify-content-center justify-content-md-start">
                              <div className="me-2 me-lg-4">
                          <a className='' href={`${process.env.REACT_APP_PORTAL_URL}/signup`}>
                          <button id='cta-signup' className='btn shadow-ss-1 fs-btn-landing-page btn-primary-gradient shadow-sm rounded-5 banner-btn-padding fw-600 '>
                                  Try for Free
                              </button>
                          </a>
                          <div className="fs-ss-13-card pt-2 ms-1 ms-lg-4 ps-lg-2 fw-500">
                            No Credit Card Needed
                          </div>
                          </div>
                          <div className="">
                          <Link to="/book-demo">
                          <button id='cta-demo' className='btn shadow-ss-1 fs-btn-landing-page btn-primary-gradient shadow-sm rounded-5 banner-btn-padding fw-600 '>
                                  Book a Demo
                              </button>
                          </Link>
                          </div>
                          </div>
                          </div>
                          </div>
                      </div>
                      <div className="col-11 col-md-6 d-none d-md-block ps-md-5 ps-lg-0">
                      <img src={Banner} className='w-100' alt="" />
                      </div>
                  </div>
              </section>
          </section>
          
          <div className="py-3"/>
          <section className="container-fluid">
          <ClientsSlider/>
          </section>
          <div className="py-2 py-lg-5"/>

          <section className="container-fluid">
          <div className="row justify-content-center text-md-center">
          <div className="col-11 col-lg-12 mx-auto text-center text-dark">
            <h2 className="fs-ss-32 fw-700 lh-sm">
            Complete Every Visit Knowing Your Notes Are Done
            </h2>
            <div className='col-12 col-lg-10 mx-auto pt-2 pt-md-3 fw-400 fs-ss-17 text-lato '>
            Imagine walking out of your clinic every day with nothing left on your to-do list. With Scribe Medix, that’s your new reality. Our intelligent AI assistant ensures your clinical documentation is completed by the time your appointment ends, giving you the freedom to fully focus on what matters - your patients during the day and your life outside of work in the evening. Say goodbye to late-night charting, burnout, and the endless burden of paperwork. Scribe Medix empowers you to reclaim your time, your energy, and your passion for medicine.
              </div>
              </div>
              </div>
              </section>

              <div className="py-4 py-lg-5"/>

          <section className="container-fluid">
          <div className="row justify-content-center align-items-center gy-4">
          <div className="col-12 col-md-4 col-lg-5 d-none d-md-block">
          <img src={Img4} alt="" className="w-100 px-1 px-lg-5" />
          </div>
          <div className="col-11 col-md-7 col-lg-5 text-dark pb-md-5">
            <h2 className="fs-ss-32 fw-700 lh-sm">
            Real-Time Transcription with Advanced Accuracy
            </h2>
            <div className='pt-2 pt-md-3 fw-400 fs-ss-17 text-lato pe-md-4'>
            Scribe Medix captures and structures information on the go - whether through dictation or live conversation. Our powerful AI ensures that every word is accurately transcribed, recognizing different accents and dialects and filtering out background noise for the clearest results. Compatible across all devices, Scribe Medix brings seamless, precise documentation to your fingertips, wherever you are.
              </div>
            </div>
            <div className="col-10 col-md-5 d-block d-md-none">
          <img src={Img4} alt="" className="w-100 px-1 px-md-5" />
          </div>
          </div>
          </section>

          <div className="py-4 py-lg-5"/>
          <section className="container-fluid ">
          <div className="row justify-content-center text-md-center ">
          <div className="text-md-center col-11 col-lg-10 text-dark">
            <h2 className="fs-ss-32 fw-700 lh-sm">
            Customizable Documentation, Designed for Your Practice
            </h2>
            <div className='pt-2 pt-md-3 fw-400 fs-ss-17 text-lato px-md-2'>
            Transform recorded patient encounters into structured, professional documentation with ease. Choose from SOAP notes, standard result formats, or create your own personalized templates that reflect your unique workflow and preferences. Our AI-powered system is flexible and adapts to your specialty, helping you capture every detail with precision and organization. Whether you’re managing routine visits or complex cases, you can rely on documentation that’s accurate, professional, and aligned with your practice’s needs. Save time, reduce errors, and focus more on delivering exceptional care. With customizable options at your fingertips, documentation becomes an asset, not a burden. 
              </div>
            </div>
          </div>
          </section>

          <Space/>
          <section className="container-fluid">
          <div className="row justify-content-center align-items-center gy-4">
          <div className="col-11 col-md-7 col-lg-5 text-dark">
            <h2 className="fs-ss-32 fw-700 lh-sm">
            Automated ICD-10 Code Recommendations
            </h2>
            <div className='pt-2 pt-md-3 fw-400 fs-ss-17 text-lato '>
            Scribe Medix enhances documentation by recommending accurate ICD-10 codes directly from your notes, streamlining your coding process. With options to upload prerecorded audio dictations or conversations, Scribe Medix ensures your notes are always EMR-ready and can integrate seamlessly with any EMR or EHR system, empowering your practice with efficient and precise documentation.
              </div>
            </div>
            <div className="col-10 col-md-4 col-lg-5">
          <img src={Img5} alt="" className="w-100 px-1 px-lg-5" />
          </div>
          
          </div>
          </section>
          <div className="py-4 py-lg-5"/>

          <section className="container-fluid text-center bg-grey2-landing-page">
          <Space/>
          <div className="col-11 col-md-12 mx-auto text-dark">
            <h2 className="fs-ss-32 fw-700 lh-sm pb-2">
            Take Your Eyes Off the Keyboard and Connect with Your Patients
            </h2>
            </div>
    <div className="row justify-content-center gy-4 mt-2">
    <div className="col-11 col-lg-3 text-md-center lh-sm">
      <div className="fs-ss-56 fw-600 text-ss-primary">
        60 sec
      </div>
      <div className="fs-ss-18 fw-600 lh-sm text-lato">
      Average chart closure time 
      </div>    
    </div>

    <div className="col-11 col-lg-3 text-md-center lh-sm">
      <div className="fs-ss-56 fw-600 text-ss-primary">
        90%
      </div>
      <div className="fs-ss-18 fw-600 lh-sm text-lato">
      Reduction in documentation tasks
      </div>    
    </div>

    <div className="col-11 col-lg-3 text-md-center lh-sm">
      <div className="fs-ss-56 fw-600 text-ss-primary">
        17,000 +
      </div>
      <div className="fs-ss-18 fw-600 lh-sm text-lato">
      Patient encounters documented
      </div>    
    </div>

    <div className='col-11 col-md-12 mx-auto pt-3 pt-md-5'>
                          <a className='me-2 me-md-4' href={`${process.env.REACT_APP_PORTAL_URL}/signup`}>
                          <button id='cta-signup' className='btn fs-ss-20 btn-primary-gradient shadow-ss-1 rounded-5 banner-btn-padding fw-700 '>
                                  Try for Free
                              </button>
                          </a>
                          <Link to="/book-demo">
                          <button id='cta-demo' className='btn fs-ss-20 btn-primary-gradient shadow-ss-1 rounded-5 banner-btn-padding fw-700 '>
                                  Book a Demo
                              </button>
                          </Link>
                          </div>
    </div>
    <Space/>
    </section>

    <Space/>

    <section className="container-fluid">
    <h2 className="col-11 col-md-12 mx-auto fs-ss-32 fw-700 lh-sm text-center pb-3">
    A Premium Solution to Transform Your Clinical Documentation
            </h2>
    <Tabs>
          <div className="text-center pe-5 pe-md-0">
            <TabList className="fs-ss-18 fw-600 py-4 mb-md-4">
              <Tab> Pay Annually </Tab>
              <Tab> Pay Monthly </Tab>
            </TabList>
          </div>

          <TabPanel>
            <YearlyPricingLanding />
          </TabPanel>

          <TabPanel>
            <MonthlyPricingLanding />
          </TabPanel>

          </Tabs>  
          </section>
            
                          <Space/>
                          <section className="container-fluid px-0">
                          <TestimonialsLandingPage/>
                          </section>
    <Space/>

    <section className="container-fluid">
    <div className='row justify-content-evenly align-items-center '>
    <div className='col-lg-5'>
    <div className='col-11 col-lg-8 mx-auto mb-4 mb-lg-0'>
    <h2 className="fs-ss-48 fw-700 lh-sm text-md-center text-lg-start">
      Frequently Asked <br className='d-none d-lg-block' />
      <span className='text-ss-primary'> Questions? </span>
    </h2>
    </div>
    </div>
    <div className='col-11 col-lg-5 pb-4 pb-md-0'>
                  <Accordion className='border-0'>
                      <Accordion.Item eventKey="0" className='bg-transparent'>
                          <Accordion.Header className='bg-transparent'>
                              <span className='faqs-heading text-black py-2'>
                              What is Scribe Medix?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body className=''>
                              <span className='faqs-ans'>
                              Scribe Medix is an innovative AI-powered solution designed to streamline and automate the process of documenting patient interactions, saving healthcare professionals time and enhancing their focus on patient care. 
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1" className='bg-transparent'>
                          <Accordion.Header className='bg-transparent'>
                              <span className='faqs-heading text-black py-2'>
                              Is Scribe Medix HIPAA Compliant?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              Yes, Scribe Medix is committed to the privacy and security of patient data. We sign business associate agreements in accordance with HIPAA requirements and adhere to all necessary administrative and technical safeguards. We use industry-standard encryption and security protocols to protect your data. However, it's important to note that HIPAA compliance also depends on users following their obligations when using the product.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2" className='bg-transparent'>
                          <Accordion.Header className='bg-transparent'>
                              <span className='faqs-heading text-black py-2'>
                              What devices can I use with Scribe Medix?
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              Scribe Medix is compatible with various devices, including smartphones, tablets, and computers. It offers flexibility to use the device that best suits your practice.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3" className='bg-transparent'>
                          <Accordion.Header className='bg-transparent'>
                              <span className='faqs-heading text-black py-2'>
                              Is there a setup fee? 
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              No, there are no setup fees associated with any of our plans. You'll only pay the subscription cost.
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="4" className='bg-transparent'>
                          <Accordion.Header className='bg-transparent'>
                              <span className='faqs-heading text-black py-2 pe-4'>
                              Can doctors see multiple patients at once without interrupting the current recording? 
                              </span>
                          </Accordion.Header>
                          <Accordion.Body>
                              <span className='faqs-ans'>
                              Yes, doctors can open Scribe Medix in multiple browser tabs on Android, PC, or other desktop systems. They can pause one recording and start another without interruption. However, this may not be fully supported on iOS devices. 
                              </span>
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  </div>
                </section>

                <div className="py-3 py-md-5" />

    <section className="container-fluid bg-dark text-center text-md-start">
      <div className="row justify-content-center align-items-center py-4">
        <div className="col-11 col-md-2 my-0 mt-md-2">
        <img src={FooterLogo} alt="" className="w-landing-page-footer" />
        </div>
        <div className="col-11 col-md-4 d-none d-md-block my-0 mt-md-2">
        <div className="fs-ss-12 text-white">
        Copyright © by Scribe Medix. All rights reserved
      </div>
        </div>
        <div className="col-11 col-md-5 col-lg-4 text-md-center text-lg-end my-4 my-md-0 mt-md-2">
        <div className=''>
          <a className='me-2 me-lg-4' href={`${process.env.REACT_APP_PORTAL_URL}/signup`}>
          <button id='cta-signup' className='text-lato btn  fs-ss-15 bg-hover-white-primary rounded-5 px-4 fw-700 '>
            Try for Free
          </button>
          </a>
          <Link to="/book-demo">
          <div id='cta-demo' className='text-lato btn fs-ss-15 bg-hover-white-primary rounded-5 px-4 fw-700'>
            Book a Demo
          </div>
          </Link>
          
          </div>
        </div>
        <div className="col-11 col-md-4 d-block d-md-none">
        <div className="fs-ss-12 text-white">
      Copyright © by Scribe Medix. All rights reserved
      </div>
        </div>
      </div>
    </section>
    </section>
    </>
  );
}

export default LandingPage;
