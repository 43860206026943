import React from "react";
import { Check, CheckLg, Quote } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const MonthlyPricingLanding = () => {
  
  return (
    <>
    <div className="row justify-content-center gy-4 bg-grey px-md-5">
          <div className="col-10 col-md-7 col-lg-3 text-center bg-white py-3 pt-md-3 pb-md-0  shadow-ss-1 rounded-4">
          <div className="">
            <div className="col-12 pb-3 pb-md-0">
            <h4 className="fs-ss-22 fw-800 text-dark lh-sm text-center pt-3">
            Core
            </h4>
              <div className="fs-ss-56 fw-ss-700 text-ss-primary">
                <span className="fs-ss-56"></span>
                <span className=""> Free </span>
              </div>
              <div className="pb-4 fs-ss-24 fw-ss-800">For 1 Month</div>
              <div className="pb-md-5 px-3 fs-ss-13 text-start fw-500">
              <div className="lh-sm pb-2"> <CheckLg className='text-ss-primary me-2'/> 20 Free Credits </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Real-time patient-doctor conversations </div>
              <div className="lh-sm pb-2"> <CheckLg className='text-ss-primary me-2'/> Customized Templates </div>
              <div className="lh-sm pb-2"> <CheckLg className='text-ss-primary me-2'/> ICD-10 Recommendations </div>
              <div className="lh-sm pb-2"> <CheckLg className='text-ss-primary me-2'/> Essential Support </div>
              <div className=""> <CheckLg className='text-ss-primary me-2'/> Upgrade Anytime </div>
              </div>
              </div>
              </div>
              </div>
          
          <div className="col-10 col-md-7 col-lg-3 mx-md-4 pt-3 pb-2 pt-md-3 pb-md-0  bg-white shadow-ss-1 rounded-4 text-center">
          <div className="">
            <div className="col-12">
            <h4 className="fs-ss-22 fw-800 text-dark lh-sm text-center pt-3 mb-0">
            Standard
            </h4>
              <div className="fs-ss-56 fw-ss-700 text-ss-primary">
                <div className="lh-sm">
                <div className="line-through fs-ss-26 fw-500 mt-3"> $129 </div>
                <span className="">$110*</span>
                <div className="fs-ss-24 text-dark fw-ss-800 pb-2">Per Month</div>
                </div>
              </div>
              <div className="pb-4 fs-ss-16 fw-600 text-ss-primary"> Promo Code: <span className="fs-ss-20 fw-800"> NYSM2025 </span> </div>                           
              <div className="pb-md-4 px-3 fs-ss-13 lh-sm text-start fw-500">
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Unlimited Dictations </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Real-time patient-doctor conversations </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Customized Templates </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> ICD-10 Recommendations </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Monthly Access </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Priority Support </div>
              <div className=""> <CheckLg className='text-ss-primary me-2'/> Perfect for Individual Practitioners </div>
              </div>
             <div className="mt-auto text-end pb-md-2 pt-5 fs-ss-10 fw-600 text-ss-primary"> Offer valid for the first 3 months.*
             </div> 

              </div>
              </div>
              </div>
            
          <div className="col-10 col-md-7 col-lg-3 text-center pt-3 pb-2 pt-md-3 pb-md-0 bg-white shadow-ss-1 rounded-4">
          <div className="">
            <div className="col-12">
            <h4 className="fs-ss-22 fw-800 text-dark lh-sm text-center pt-3">
            Premium
            </h4>
            <div className="fs-ss-56 fw-ss-700 text-ss-primary">
                <div className="lh-sm">
                <div className="line-through fs-ss-26 fw-500 mt-3"> $179 </div>
                <span className="">$149*</span>
                <div className="fs-ss-24 text-dark fw-ss-800 pb-2">Per Month</div>
                </div>
              </div>
              <div className="pb-4 fs-ss-16 fw-600 text-ss-primary"> Promo Code: <span className="fs-ss-20 fw-800"> NYPM2025 </span> </div>                           
              <div className="pb-md-5 px-3 fs-ss-13 lh-sm text-start fw-500">
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Integration with Athena </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Unlimited Dictations </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Real-time patient-doctor conversations </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Customized Templates </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> ICD-10 Recommendations </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Monthly Access </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Priority Support </div>    
              <div className=""> <CheckLg className='text-ss-primary me-2'/> For Clinics with Athena </div>    
              </div>
              <div className="mt-auto text-end pb-md-2 pt-5 pt-lg-0 fs-ss-10 fw-600 text-ss-primary"> Offer valid for the first 3 months.*
              </div> 
            </div>
            </div>
            </div>

            <div className='pt-2 text-center pt-md-4'>
                          <a className='me-2 me-md-4' href={`${process.env.REACT_APP_PORTAL_URL}/signup`}>
                          <button id='cta-signup' className='btn fs-ss-20 btn-primary-gradient shadow-ss-1 rounded-5 banner-btn-padding fw-700 '>
                                  Try for Free
                              </button>
                          </a>
                          <Link to="/book-demo">
                          <button id='cta-demo' className='btn fs-ss-20 btn-primary-gradient shadow-ss-1 rounded-5 banner-btn-padding fw-700 '>
                                  Book a Demo
                              </button>
                          </Link>
                          </div>
                          </div>
    </>
  );
};

export default MonthlyPricingLanding;
