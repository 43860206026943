import React from 'react';

// Sources

const CommonBanner = (props) => {
  return (
    <>
    <section id='website-responsive' className={props.BgImg}>
      <div className="row justify-content-center py-lg-5 my-lg-5">
        <h1 className={`col-11 col-md-10 ${props.Cls} text-uppercase py-md-5`}>
          <div className="fs-ss-26 fw-600 lh-sm">
            {props.subTitle}
          </div>
          <div className="fs-ss-40 fw-600 lh-sm">
            {props.Title}
          </div>
        </h1>
      </div>
    </section>
    </>
  );
}

export default CommonBanner;
