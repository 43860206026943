import React, { Component } from "react";
import Slider from "react-slick";
import Icon1 from '../assets/images/LandingPage/Icon1.png';
import Icon2 from '../assets/images/LandingPage/Icon2.webp';
import Icon3 from '../assets/images/LandingPage/Icon3.png';
import Icon4 from '../assets/images/LandingPage/Icon4.png';
import Icon5 from '../assets/images/LandingPage/Icon5.webp';
import Marquee from "react-fast-marquee";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 8000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
          }
        }
      ]
    };
    return (
      <>
      <div id="slider-client-icons">
        <div id="slick-testimonials" className="col-12 col-md-9 mx-auto mt-md-5 mb-lg-0 mb-5 mt-4 align-items-center">
        <h2 className="fs-ss-32 fw-700 lh-sm text-dark text-center pb-4 pb-md-5">
          Trusted by Healthcare Experts
        </h2>
      <div className="d-block d-lg-none">
      <Marquee>
      <img src={Icon1} className='w-slider-client mx-auto me-5 px-md-3' alt="" />
      <img src={Icon2} className='w-slider-client mx-auto me-5 px-md-3' alt="" />
      <img src={Icon3} className='w-slider-client mx-auto me-5 px-md-3' alt="" />
      </Marquee>
      </div>
      <div className="d-none d-lg-block text-center">
      <img src={Icon1} className='w-slider-client mx-auto me-5 px-md-3' alt="" />
      <img src={Icon2} className='w-slider-client mx-auto me-5 px-md-3' alt="" />
      <img src={Icon3} className='w-slider-client mx-auto me-5 px-md-3' alt="" />  
      </div>
      </div>
      </div>
      </>
    );
  }
}