import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import CommonBanner from '../components/CommonBanner';
import TransparentHeaderTwo from '../components/TransparentHeaderTwo';
import TwoImgsTwoTexts from '../components/TwoImgsTwoTexts';
import Footer from '../components/Footer';

// Sources
import Icon1 from '../assets/images/careers/Icon1.webp'
import Icon2 from '../assets/images/careers/Icon2.webp'
import Icon3 from '../assets/images/careers/Icon3.webp'
import Icon4 from '../assets/images/AboutUs/Icons/4.png'
import Icon5 from '../assets/images/AboutUs/Icons/5.png'
import Img1 from '../assets/images/AboutUs/New/Img1.webp'
import Img2 from '../assets/images/AboutUs/New/Img2.webp'
import Img3 from '../assets/images/AboutUs/New/Img3.webp'
import Space from '../components/Space';
import { Link } from 'react-router-dom';


const CareersPage = (props) => {

  useEffect((props)=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <Helmet>
        <title> Scribe Medix: Careers </title> 
      </Helmet>

      <section id='website-responsive'>
    <TransparentHeaderTwo userAuth={props.userAuth} userData={props.userData}/>
    <CommonBanner
    BgImg="container-fluid bg-common-banner py-5 bg-careers"
    Cls="text-white"
    subTitle="Transform Healthcare "
    Title="Careers"
    />
    <Space/>
    <section id='website-responsive' className="container-fluid px-lg-5">
    <div className="">
    <div className="col-11 col-lg-10 mx-auto text-center pb-4 pb-md-5">
    <h2 className='fs-ss-35 fw-700 lh-sm' >
    Why Work With Us?
          </h2>
          <p className='fs-ss-15 pt-2 fw-400 lh-lg text-poppins' >
          At Scribe Medix, we’re more than just a workplace—we’re a community dedicated to transforming healthcare through innovation, collaboration, and excellence. When you join our team, you become part of a mission-driven organization that values your growth, well-being, and contributions. Whether you’re a seasoned professional or just starting your career, we offer a dynamic environment where you can thrive, learn, and make a real impact. Together, we’re shaping the future of healthcare, one step at a time.     
          </p>
      </div>
      <div id='website-responsive' className="row justify-content-center align-items-end gy-4 px-lg-5">
      <div className="col-11 col-md-3 text-center" >
          <div className="">
            <img className='w-icons-about-us' src={Icon1} alt="Icon 1" />
          </div>
          <div className='fs-ss-20 pt-3 pt-md-4 fw-700 lh-sm '>
          Career Growth <br className="" /> Opportunities
          </div>
        </div>
        <div className="col-11 col-md-3 text-center" >
          <div className="">
            <img className='w-icons-about-us' src={Icon2} alt="Icon 2" />
          </div>
          <div className='fs-ss-20 pt-3 pt-md-4 fw-700 lh-sm '>
          Innovative Work <br className="" /> Environment
          </div>
        </div>
        <div className="col-11 col-md-3 text-center" >
          <div className="">
            <img className='w-icons-about-us' src={Icon3} alt="Icon 3" />
          </div>
          <div className='fs-ss-20 pt-3 pt-md-4 fw-700 lh-sm '>
          Work-Life <br /> Balance
          </div>
        </div>
      </div>
      </div>
    </section>
    <Space/>

    <section id='website-responsive' className="container-fluid">
    <h2 className='fs-ss-35 fw-700 lh-sm text-center' >
      Jobs
          </h2>
      <div className="row justify-content-center mt-4 text-center text-md-start">
        <div className="d-md-flex align-items-center border border-1 border-primary py-2 px-5 col-10 col-lg-7 rounded-5 shadow-ss-1">
          <div className="fs-ss-18 text-dark fw-500">
            Sales & Marketing Internship
          </div>
          <div className="text-center text-md-end mt-3 mt-md-0 col-md">
            <a target='_blank' href='https://docs.google.com/forms/d/e/1FAIpQLSekK7fHwPwm4gqC0tM2rENp7-LRL9Sz_RIQZa-j7BUzAoNd-w/viewform' className='btn shadow-sm border-bg-hover-primary-border rounded-5 py-2 px-4 fw-500 fs-ss-15'> Apply Now </a>
          </div>
        </div>
      </div>
    </section>
    
    <Space/>
    <Footer/>
    </section>
    </>
  );
}

export default CareersPage;
