import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
// Sources
import HeaderLogo from '../assets/images/Home/HeaderLogo.png'
import {PersonFill} from 'react-bootstrap-icons';
import NavBarLink from './NavBarLink';
import NavBarLink2 from './NavBarLink2';

const TransparentHeaderTwo = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <>
    <section className="sticky-top bg-white sticky-md-none">
      <Navbar expand="xl" className="py-2 pe-4" id="website-nav" style={{borderBottom:"1px solid #eaeaea"}}>
        <div className='container-fluid px-0'>
            <Navbar.Brand className='navbar-brand px-lg-5 px-4'>
            <Link className="navbar-brand" to="/">
              <img className='header-logo py-2 my-1 px-md-4' src={HeaderLogo} alt="Scribe Medix Logo" />
            </Link>
            </Navbar.Brand>

                
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} className='rounded-3 fs-ss-btn mb-menu'/>
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton>

                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                <Link className="navbar-brand" to="/">
                  <img className='header-logo' src={HeaderLogo} alt="Scribe Medix Logo" />
                </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            {/* <hr /> */}
            <Offcanvas.Body>
            <ul className="navbar-nav me-auto align-items-lg-center">
            <li className="nav-item pt-1">
          <Link to="/about-us" className={splitLocation [1] === "about-us" ? "nav-link hover-pri fw-500  text-ss-primary" : "nav-link hover-pri fw-500 "}> About Us</Link>
          </li>

          <NavBarLink2
              Name="Our Solutions"
              Link_To="our-solutions"
              DropdownMenu={
                <>
              <NavBarLink
              Name="ConvoScribe"
              Link_To="convo-scribe"
              Border=""          
              />

              <NavBarLink
              Name="Virtual Scribe"
              Link_To="virtual-scribe"
              Border=""          
              />
              </>}
              />

          <li className="nav-item pt-1">
          <Link to="/blogs" className={splitLocation [1] === "blogs" ? "nav-link hover-pri fw-500  text-ss-primary" : "nav-link hover-pri fw-500 "}> Blogs </Link>
          </li>
          <li className="nav-item pt-1">
          <Link to="/pricing" className={splitLocation [1] === "pricing" ? "nav-link hover-pri fw-500  text-ss-primary" : "nav-link hover-pri fw-500 "}> Pricing </Link>
          </li>
          <li className="nav-item pt-1">
          <Link to="/book-demo" className={splitLocation [1] === "book-a-demo" ? "nav-link hover-pri fw-500  text-ss-primary" : "nav-link hover-pri fw-500 "}> Book Demo </Link>
          </li>
          <li className="nav-item pt-1">
          <Link to="/careers" className={splitLocation [1] === "careers" ? "nav-link hover-pri fw-500  text-ss-primary" : "nav-link hover-pri fw-500 "}> Careers </Link>
          </li>
          {/* <li className="nav-item pt-1">
          <Link to="/contact-us" className={splitLocation [1] === "contact-us" ? "nav-link hover-pri fw-500  text-ss-primary" : "nav-link hover-pri fw-500 "}> Contact Us </Link>
          </li> */}
        </ul>

        <ul className='navbar-nav align-items-lg-center'>
        {/* <li className=''> */}
              {/* {props.userAuth === true ?(
                      <span className='d-flex align-items-center'>
                          <a href="/account/home" className="btn shadow-sm bg-grey2 rounded-5 py-1 px-4 fw-500 fs-ss-15"><PersonFill size={18}/> <span className='text-popins'>{props.userData.full_name}</span></a>
                      </span>
                  ):(
                      <Link className='text-decoration-none' to="/account"> 
                          <div className='nav-link text-dark fw-500 mt-4 mb-3 my-md-0'>
                          <span className=''/> Sign in  <span className='ps-2'/>
                          </div>
                      </Link>
                  )}
              </li> */}
              <li className='mt-4 mb-3 my-lg-0'>
                <a className='text-decoration-none' href={`${process.env.REACT_APP_PORTAL_URL}`}> 
                <button className='btn shadow-sm bg-hover-primary-border rounded-5 py-2 px-4 fw-500 fs-ss-15'>
                <span className='d-inline d-md-none'> &nbsp; &nbsp; </span> Sign in <span className='d-inline d-md-none'> &nbsp; &nbsp; </span>
              </button>
                </a>
              </li>

          <li className='pe-lg-3 ps-lg-2'>
            <a href={`${process.env.REACT_APP_PORTAL_URL}/signup`}>
              <button className='btn shadow-sm border-bg-hover-primary-border rounded-5 py-2 px-4 fw-500 fs-ss-15'>
                Try for Free
              </button>
            </a>
          </li>
        </ul>
        
            </Offcanvas.Body>
            </Navbar.Offcanvas>
            </div>        
            </Navbar>

            <section className='d-block d-md-none'>
            <div className='text-center py-3'>
              <Link className='' to="/account/sign-up">
              <button className='w-btn-header btn fs-ss-btn btn-primary-gradient shadow-sm rounded-3 banner-btn-padding fw-600 '>
                Try for Free
                </button>
              </Link>
              <div className="fs-ss-12 pt-2 ms-1 ms-md-4 ps-md-1 fw-500">
                No credit card needed
              </div>
            </div>
            </section>
            </section>

    </>
  )
}

export default TransparentHeaderTwo;